import React, { useEffect, Suspense } from 'react';
import { hot } from 'react-hot-loader/root';
import { useMediaQueryDevice } from '@/components/MediaQuery';

import Homepage from './Homepage';

function App(): JSX.Element {
  const { deviceType } = useMediaQueryDevice();
  useEffect(() => {
    window.document.documentElement.className = deviceType;
  }, [deviceType]);

  useEffect(() => {
    // adapt mobile height
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', documentHeight);
    documentHeight();
  }, []);

  return (
    <div className={`App ${deviceType}`}>
      <Suspense fallback={''}>
        <Homepage />
      </Suspense>
    </div>
  );
}

export default hot(App);
