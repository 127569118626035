import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import './themes/index.less';

export default function main(App: () => JSX.Element): void {
  const container = document.getElementById('root');

  if (container) {
    const root = createRoot(container);
    root.render(
      <div>
        <Suspense fallback="">
          <App />
        </Suspense>
      </div>,
    );
  }
}
