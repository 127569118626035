import React, { FC } from 'react';
import classnames from 'classnames';

import imgLight from './assets/light.png';
import { ReactComponent as IconMail } from './assets/icon_mail.svg';
import { ReactComponent as IconLogo } from './assets/logo.svg';
import { ReactComponent as IconBusiness } from './assets/icon_business.svg';
import { ReactComponent as IconClient } from './assets/icon_client.svg';
import { ReactComponent as IconContact } from './assets/icon_contact.svg';
import { ReactComponent as IconGlobal } from './assets/icon_global.svg';
import { ReactComponent as IconLogoIf } from './assets/icon_logo_if.svg';

import styles from './index.module.less';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import { useSpring, animated } from '@react-spring/web';

const IndexPage: FC = function () {
  const { deviceType } = useMediaQueryDevice();
  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1000,
    config: {
      tension: 280,
      friction: 60,
    },
  });
  return (
    <main className={classnames(styles['main-layout'], styles[deviceType])}>
      <section className={styles['main-title']}>
        <IconLogo />
        <div className={styles['main-sub-title']}>Reshaping the world with Web3 technology</div>
        <animated.div
          className={styles['main-light']}
          style={{
            ...springs,
          }}>
          <img src={imgLight} alt="Picture of light" />
        </animated.div>
      </section>
      <div></div>
      <footer>
        <div className={styles['footer-intro-container']}>
          <div className={styles['footer-intro']}>
            <div className={styles['footer-intro-card']}>
              <div className={styles['footer-intro-card-icon']}>
                <IconLogoIf />
              </div>
              {`IntoFuture is a full-cycle technology development firm that specializes in providing tailored solutions to our clients in the web3, AI, and other technology sectors. Our team of experts is dedicated to helping businesses thrive in the digital age by providing cutting-edge technology solutions that align with their unique needs and goals.`}
            </div>
            <div className={styles['footer-intro-card']}>
              <div className={styles['footer-intro-card-icon']}>
                <IconBusiness />
              </div>
              {`Our services range from UI/UX design, smart contract development, to data analytics. We work closely with our clients to understand their vision, goals, and requirements to deliver custom-made solutions that drive business growth and enhance user experience.`}
            </div>
            <div className={styles['footer-intro-card']}>
              <div className={styles['footer-intro-card-icon']}>
                <IconGlobal />
              </div>
              {`As a global company, we have a proven track record of serving top-tier clients from various industries worldwide. Our team of highly skilled professionals is well-versed in the latest technologies and trends, enabling us to offer innovative solutions that help our clients stay ahead of the competition.`}
            </div>
            <div className={styles['footer-intro-card']}>
              <div className={styles['footer-intro-card-icon']}>
                <IconClient />
              </div>
              {`At our core, we prioritize transparency, communication, and collaboration, ensuring that our clients have a seamless experience from start to finish. Our goal is to establish long-term partnerships with our clients, delivering ongoing support and solutions that drive their businesses forward.`}
            </div>
            <div className={styles['footer-intro-card']}>
              <div className={styles['footer-intro-card-icon']}>
                <IconContact />
              </div>
              {`If you're looking for a trusted technology partner to help you navigate the ever-evolving digital landscape, we're here to help. Contact us today to learn more about how we can help take your business to the next level.`}
            </div>
          </div>
        </div>
        <div className={styles['footer-contact']}>
          <IconMail />
          <a href="mailto:enquiry@intofuture.info">enquiry@intofuture.info</a>
        </div>
      </footer>
    </main>
  );
};

export default IndexPage;
